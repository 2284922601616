/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button/Button";
import ComboBoxObject from "../../../components/ComboBox/ComboBoxObject";
import Chart from "../../../components/BOCO/Chart/Chart";
import AuthorizationLayout from "../../../components/HOC/AuthorizationLayout/AuthorizationLayout";
import ArrowDownSvg from "../../../components/SvgComponents/ArrowDownSvg";
import ArrowUpSvg from "../../../components/SvgComponents/ArrowUpSvg";
import FilterSvg from "../../../components/SvgComponents/FilterSvg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
   getListSociete,
   getOffres,
   getUsers,
   getVentesDashboardAnnually,
   getVentesDashboardMonthly,
   getVentesDashboardWeekly,
} from "../../../core/services/httpRequest/axios";
import GeoExclu from "../../../components/BOCO/GeoExclu/GeoExclu";
import { ChartDataItem } from "./utils/utils";
import ButtonChart from "../../../components/BOCO/ButtonChart/ButtonChart";
import CSVDash from "../../../components/BOCO/CSVContainers/CSVDash";
import moment from "moment";
import "./styles/dash.scss";
import _ from "lodash";
import PermissionWrapper from "../../../components/HOC/PermissionWrapper";

const Dash = () => {
   const [toggleFilters, setToggleFilters] = useState(false);
   const [view, setView] = useState("MONTHLY");
   const [date, setDate] = useState(new Date());
   const [filters, setFilters] = useState({
      week: moment().week(),
      month: moment().month(),
      year: moment().year(),
      idSociete: "",
      idResponsableVendeur: "",
      idVendeur: "",
      nomOffre: "",
      departements: "",
   });
   const [vendeurs, setVendeurs] = useState([]);
   const [responsablesVendeurs, setResponsablesVendeurs] = useState([]);
   const [offers, setOffers] = useState([]);
   const [societes, setSocietes] = useState([]);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);

   const currentUser = useSelector((state) => state.auth.broker);
   const { t } = useTranslation();

   const [listResponsableV, setResponsablesV] = useState();
   const [listVendeurs, setListVendeurs] = useState();
   const [listSociete, setListSociete] = useState();
   const [authorization, setAuthorization] = useState("");
   const authorizations = useSelector((state) => state?.params?.authorizations);
   const [isInitialLoad, setIsInitialLoad] = useState(true);

   // init autorisation
   useEffect(() => {
      setAuthorization(currentUser.type);
   }, [currentUser]);

   const VUES = [
      {
         text: t("dash.weekly"),
         value: "WEEKLY",
      },
      { text: t("dash.monthly"), value: "MONTHLY" },
      { text: t("dash.annually"), value: "ANNUALLY" },
   ];

   function resetFilters() {
      setFilters({
         week: moment().week(),
         month: moment().month(),
         year: moment().year(),
         idSociete: "",
         idResponsableVendeur: "",
         idVendeur: "",
         nomOffre: "",
         departements: "",
      });
      callDashAPI(view, {
         week: moment().week(),
         month: moment().month(),
         year: moment().year(),
         idSociete: "",
         idResponsableVendeur: "",
         idVendeur: "",
         nomOffre: "",
         departements: "",
      });
   }

   function callDashAPI(view, filters) {

      if (isInitialLoad) {
        setLoading(false);
        return setData([]);
      }
      // setData([]);
      // ici regarder les datas
      // si je clique trop vite j'ai des doublons
      const months = [
         "Janvier",
         "Fevrier",
         "Mars",
         "Avril",
         "Mai",
         "Juin",
         "Juillet",
         "Aout",
         "Septembre",
         "Octobre",
         "Novembre",
         "Decembre",
      ];
      setLoading(true);
      if (view === "WEEKLY") {
         return getVentesDashboardWeekly({ ...filters, month: undefined }).then(
            (res) => {
               setData(
                  res.data
                     // ?.filter((item) => item?.week === filters?.week)
                     ?.map((d) =>
                        new ChartDataItem(
                           view,
                           d.name,
                           d.dayNumber,
                           d.month,
                           d.year,
                           d.commissionAmount,
                           d.totalSales
                        ).format()
                     )
               );
               setLoading(false);
            }
         );
      }
      if (view === "MONTHLY") {
         return getVentesDashboardMonthly({ ...filters, week: undefined }).then(
            (res) => {
               setData(
                  res.data
                     ?.filter((item) => item.month === months[filters.month])
                     ?.map((d) =>
                        new ChartDataItem(
                           view,
                           d.name,
                           d.dayNumber,
                           d.month,
                           d.year,
                           d.commissionAmount,
                           d.totalSales
                        ).format()
                     )
               );
               setLoading(false);
            }
         );
      }
      if (view === "ANNUALLY") {
         return getVentesDashboardAnnually({
            ...filters,
            week: undefined,
            month: undefined,
         }).then((res) => {
            setData(
               res.data
                  ?.filter((item) => item?.year === filters?.year)
                  ?.map((d) =>
                     new ChartDataItem(
                        view,
                        d.name,
                        d.dayNumber,
                        d.month,
                        d.year,
                        d.commissionAmount,
                        d.totalSales
                     ).format()
                  )
            );
            setLoading(false);
         });
      } else {
         setLoading(false);

         return setData([]);
      }
   }

   function handleChangeView(event) {
      setFilters({
         ...filters,
         week: moment().week(),
         month: moment().month(),
         year: moment().year(),
      });
      setDate(new Date());
      setView(event.target.value);
   }

   useEffect(() => {
      if (currentUser.type === "ADMIN_COURTAGE") {
      }
      if (currentUser.type === "RESPONSABLE_VENDEUR") {
      }
      if (currentUser.type !== "VENDEUR") {
         getUsers().then((res) => {
            let tmpRespos = res.data?.filter(
               (u) => u.type === "RESPONSABLE_VENDEUR" && u.statut === "ACTIF"
            );
            let tmpVendeurs = res.data?.filter(
               (u) => u.type === "VENDEUR" && u.statut === "ACTIF"
            );
            setResponsablesVendeurs(tmpRespos);

            setVendeurs(tmpVendeurs);
         });
      }

      if (currentUser.type === "ADMIN_PRIMEO") {
         getListSociete().then((res) => {
            setSocietes(res.data);
         });
      }
      getOffres().then((res) => {
         setOffers(res.data);
      });
   }, []);

   useEffect(() => {
      callDashAPI(view, filters);
   }, [view, filters.week, filters.month, filters.year]);

   // Conditions filter societe/responsable/vendeurs
   useEffect(() => {
      let newSocietes = societes;
      let newResponsablesV = responsablesVendeurs;
      let newVendeurs = vendeurs;

      if (filters.idSociete) {
         newResponsablesV = filterResponsable();
         if (filters.idResponsableVendeur) {
            newSocietes = filterSociete();
            newVendeurs = filterVendeurs();
         } else {
            newVendeurs = filterVendeursWithoutResponsable();
         }
      }
      if (filters.idResponsableVendeur) {
         if (filters.idSociete) {
            newResponsablesV = filterResponsable();
         } else {
            newVendeurs = filterVendeurs();
         }
      }
      if (filters.idVendeur) {
         if (filters.idResponsableVendeur) {
            newVendeurs = filterVendeurs();
            if (newVendeurs.length === 0) {
               setFilters({
                  ...filters,
                  idVendeur: "",
               });
            }
         } else {
            newVendeurs = filterVendeursWithoutResponsable();
         }
      }

      setListSociete(
         newSocietes.map((b) => ({
            value: b.idSociete,
            name: b.nomSociete,
         }))
      );
      setResponsablesV(
         newResponsablesV.map((r) => ({
            value: r.idUtilisateur,
            name: r.prenom + " " + r.nom,
         }))
      );
      setListVendeurs(
         newVendeurs.map((r) => ({
            value: r.idUtilisateur,
            name: r.prenom + " " + r.nom,
         }))
      );
   }, [filters, societes, responsablesVendeurs, vendeurs]);

   useEffect(() => {
     if (!isInitialLoad) {
       callDashAPI(view, filters);
     }
   }, [isInitialLoad]);

   const filterSociete = () => {
      if (!!societes && societes?.length > 0) {
         return societes.filter((r) => r.idSociete === filters.idSociete);
      } else {
         return societes;
      }
   };

   const filterResponsable = () => {
      if (!!responsablesVendeurs && responsablesVendeurs?.length > 0) {
         return responsablesVendeurs.filter(
            (r) => r.idSocieteCourtage === filters.idSociete
         );
      } else {
         return responsablesVendeurs;
      }
   };

   const filterVendeurs = () => {
      if (!!vendeurs && vendeurs?.length > 0 && filters.idResponsableVendeur) {
         return vendeurs.filter(
            (v) => v.idResponsable === filters.idResponsableVendeur
         );
      } else {
         return vendeurs;
      }
   };

   const filterVendeursWithoutResponsable = () => {
      if (!!vendeurs && vendeurs?.length > 0 && filters.idSociete) {
         return vendeurs.filter(
            (v) => v.idSocieteCourtage === filters.idSociete
         );
      } else {
         return vendeurs;
      }
   };

   const selectWithJustVendeur = (e) => {
      const vendeurInfo = vendeurs.filter((v) => v.idUtilisateur === e);

      const isAdmin = authorization === authorizations[0];

      const condition = isAdmin
         ? filters.idResponsableVendeur === "" &&
         filters.idSociete === "" &&
         filters.idVendeur === ""
         : filters.idResponsableVendeur === "" && filters.idVendeur === "";

      if (condition) {
         setFilters({
            ...filters,
            idResponsableVendeur: vendeurInfo[0].idResponsable,
            idSociete: vendeurInfo[0].idSocieteCourtage,
            idVendeur: e,
         });
      } else {
         setFilters({
            ...filters,
            idVendeur: e,
         });
      }
   };

   const selectWithJustResponsable = (e) => {
      const responsableInfo = responsablesVendeurs.filter(
         (v) => v.idUtilisateur === e
      );

      if (
         filters.idResponsableVendeur === "" &&
         filters.idSociete === "" &&
         filters.idVendeur === ""
      ) {
         setFilters({
            ...filters,
            idResponsableVendeur: e,
            idSociete: responsableInfo[0].idSocieteCourtage,
         });
      } else {
         setFilters({
            ...filters,
            idResponsableVendeur: e,
         });
      }
   };

   const updateIsInitialLoad = (newValue) => {
      setIsInitialLoad(newValue);
    };
  

   return (
      <div className="dash_container" id="dash_container">
         <div className="dash_header">
            <div className="top">
               <div>
                  <div className="left">
                     <h2>
                        {t("dash.welcome")} {currentUser?.firstName}
                     </h2>
                     <Button
                        className={
                           toggleFilters
                              ? "blue outline small"
                              : "blue outline small black"
                        }
                        onClick={() => setToggleFilters(!toggleFilters)}
                     >
                        <FilterSvg fill={toggleFilters ? "#0ABBF0" : "#000"} />
                        <p>{t("users.filters")}</p>

                        {toggleFilters ? (
                           <ArrowUpSvg fill="black" />
                        ) : (
                           <ArrowDownSvg fill="black" />
                        )}
                     </Button>
                     <p
                        style={{
                           textDecoration: "underline",
                           cursor: "pointer",
                           margin: "0 0 0 12px",
                        }}
                        onClick={() => resetFilters()}
                     >
                        Effacer
                     </p>
                  </div>
               </div>
               <PermissionWrapper requiredPermission={["EXPORT_SALE"]}>
                  <div className="cta">
                     <div className="button-list">
                        <Button
                           className="blue small square"
                           style={{ marginRight: "12px" }}
                        // disabled={listSelectedUser.length <= 0}
                        // onClick={() => setToggle(!toggle)}
                        >
                           {/* <DownloadUserCSVButton view={view} filter={filters} /> */}
                           <CSVDash view={view} filters={filters} />
                        </Button>
                     </div>
                  </div>
               </PermissionWrapper>
            </div>

            {toggleFilters && (
               <div className="dash_header_filters">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                     {currentUser.type === "ADMIN_PRIMEO" && (
                        <div>
                           <ComboBoxObject
                              label={t("dash.societies")}
                              list={listSociete}
                              value={filters?.idSociete}
                              canBeCanceled={true}
                              onChange={(e) =>
                                 setFilters({ ...filters, idSociete: e })
                              }
                           />
                        </div>
                     )}

                     <div>
                        <AuthorizationLayout
                           current={currentUser.type}
                           authorized={["ADMIN_PRIMEO", "ADMIN_COURTAGE"]}
                        >
                           <ComboBoxObject
                              label={t("dash.respos")}
                              list={listResponsableV}
                              value={filters?.idResponsableVendeur}
                              canBeCanceled={true}
                              onChange={(e) => {
                                 selectWithJustResponsable(e);
                              }}
                           />
                        </AuthorizationLayout>
                     </div>
                     <div>
                        <AuthorizationLayout
                           current={currentUser.type}
                           authorized={[
                              "ADMIN_PRIMEO",
                              "ADMIN_COURTAGE",
                              "RESPONSABLE_VENDEUR",
                           ]}
                        >
                           <ComboBoxObject
                              label={t("dash.vendeurs")}
                              list={listVendeurs}
                              value={filters?.idVendeur}
                              canBeCanceled={true}
                              onChange={(e) => {
                                 selectWithJustVendeur(e);
                              }}
                           />
                        </AuthorizationLayout>
                     </div>

                     <div>
                        <ComboBoxObject
                           label={t("dash.offers")}
                           list={offers?.map((o) => {
                              return { name: o, value: o };
                           })}
                           value={filters?.nomOffre}
                           canBeCanceled={true}
                           onChange={(e) => {
                              setFilters({ ...filters, nomOffre: e });
                           }}
                        />
                     </div>
                     <div>
                        <GeoExclu
                           label={t("dash.regions")}
                           value={filters?.departements}
                           onChange={(e) => {
                              setFilters({ ...filters, departements: e });
                           }}
                        />
                     </div>
                  </div>
                  <div className="btn_container">
                     <Button
                        className="blue"
                        onClick={() => {
                           callDashAPI(view, filters);
                        }}
                     >
                        {t("users.filter")}
                     </Button>
                  </div>
               </div>
            )}
         </div>
         {/* </div> */}

         <div className="dash_content_wrapper">
            <div className="dash_content">
               <div className="dash_content_head">
                  <h3>
                     {t("dash.chartTitle")}
                     <span className="blue" style={{ fontWeight: "bold" }}>
                        {" "}
                        {moment(date).year()}
                     </span>
                  </h3>
                  <div className="chart_setter">
                     {view === "WEEKLY" && (
                        <ButtonChart
                           name="week"
                           view={view}
                           date={date}
                           onChange={(newFilters, newDate) => {
                              setFilters({
                                 ...filters,
                                 week: newFilters.week,
                                 year: newFilters.year,
                              });
                              setDate(new Date(newDate));
                           }}
                        />
                     )}
                     {view === "MONTHLY" && (
                        <ButtonChart
                           name="month"
                           view={view}
                           date={date}
                           onChange={(newFilters, newDate) => {
                              setFilters({
                                 ...filters,
                                 month: newFilters.month,
                                 year: newFilters.year,
                              });
                              setDate(new Date(newDate));
                           }}
                        />
                     )}
                     {view === "ANNUALLY" && (
                        <ButtonChart
                           name="year"
                           view={view}
                           date={date}
                           onChange={(newFilters, newDate) => {
                              setFilters({ ...filters, year: newFilters.year });
                              setDate(new Date(newDate));
                           }}
                        />
                     )}

                     <Select
                        className="selectChart"
                        onChange={handleChangeView}
                        label={"vue"}
                        value={view}
                     >
                        {VUES.map((vue) => (
                           <MenuItem key={vue.value} value={vue.value}>
                              {vue.text}
                           </MenuItem>
                        ))}
                     </Select>
                  </div>
               </div>
               {<Chart data={data} view={view} loading={false} isInitialLoad={isInitialLoad} updateIsInitialLoad={updateIsInitialLoad} />}
            </div>
         </div>
      </div>
   );
};

export default Dash;
