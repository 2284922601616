import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { checkEnterKeyDown } from "../../../utils";
import { useTranslation } from "react-i18next";
import { createTripicaOrder } from "../../../utils/tripicaOrder";
import moment from "moment";
import { Modal } from "@mui/material";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import PDLBloc from "../../../components/BlocsComponents/PDLBloc";
import OfferBloc from "../../../components/BlocsComponents/OfferBloc";
import ListDropDown from "../../../components/SvgComponents/ListDropDown";
import List from "../../../components/SvgComponents/List";
import IdentityBloc from "../../../components/BlocsComponents/IdentityBloc";
import BillingBloc from "../../../components/BlocsComponents/BillingBloc";
import PaymentBloc from "../../../components/BlocsComponents/PaymentBloc";
import ErrorCodeListDisplay from "../../../components/ErrorCodeList/ErrorCodeListDisplay";
import ResumeBloc from "../../../components/ResumeBloc/ResumeBloc";
import * as saleActions from "../../../core/stores/saleStore";
import * as paramsStore from "../../../core/stores/paramsStore";
import * as authStore from "../../../core/stores/authStore";
import handleRequestResponse from "../../../utils/responsesHandler";
import { createOrder } from "../../../core/services/httpRequest/axios";
// scss
import "./styles/subscription.scss";
import { analyseErrorCodes } from "../../../utils/errorCodeListHandler";
import _ from "lodash";
import Loader from "../../../components/Loader/Loader";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import { sendNotif } from "../../../core/stores/utils";
import { update } from "../../../core/stores/paramsStore";
moment.locale("fr");

const Subscription = () => {
   const [blocState, setBlocState] = useState(false);
   const [open, setopen] = useState(false);
   const [openPdl, setOpenPdl] = useState(false);
   const [openOffre, setOpenOffre] = useState(false);
   const [openIdentity, setOpenIdentity] = useState(true);
   const [openFacturation, setOpenFacturation] = useState(false);
   const [openPayment, setOpenPayment] = useState(false);
   const [errors, setErrors] = useState([]);
   const [loading, setLoading] = useState(false);

   const { t } = useTranslation();
   const history = useHistory();
   const dispatch = useDispatch();

   const sale = useSelector((state) => state.sale);

   const currentSociete = useSelector((state) => state.auth.society);

   const StoreDefaultValues = { ...sale };

   useEffect(() => {
      dispatch(paramsStore.update({ target: "SGT_CODE", value: "" }));
      if(sale?.saleTCOStep?.includes('VALIDATION')){
         dispatch(saleActions.empty())
      }
   }, []);

   // init du formulaire
   const methods = useForm({
      mode: "onBlur",
      reValidateMode: "onBlur",
      shouldFocusError: true,
      shouldUseNativeValidation: false,
      defaultValues: StoreDefaultValues,
   });

   // function open management
   function openAll() {
      setOpenPdl(true);
      setOpenOffre(true);
      setOpenIdentity(true);
      setOpenFacturation(true);
      setOpenPayment(true);
      setBlocState(true);
   }

   function closeAll() {
      setOpenPdl(false);
      setOpenOffre(false);
      setOpenIdentity(false);
      setOpenFacturation(false);
      setOpenPayment(false);
      setBlocState(false);
   }

   function annul() {
      dispatch(saleActions.empty());
      history.push("/home");
   }

   function submit(data) {
      setErrors([]);
      setLoading(true);

      let shouldSendQuote = currentSociete?.autoSendQuote;

      if(shouldSendQuote){
         dispatch(saleActions.sendQuoteToClient())
      }

      let order = createTripicaOrder(data, sale);
      order.sendMail = true
      createOrder(order)
         .then((res) => {
            // ICI fonction errorCodeList

            handleRequestResponse(
               res,
               () => {
                  if (
                     _.compact(
                        res.data.errorCodeList.map((err) => {
                           console.log(err);
                           return analyseErrorCodes(err)
                        }
                        )
                     )?.length === 0
                  ) {
                     dispatch(
                        saleActions.update({
                           target: "saleTCOStep",
                           value: ["SUBSCRIPTION", "RECAP"],
                        })
                     );
                     dispatch(
                        saleActions.update({
                           target: "idVenteTripica",
                           value: res.data.productOrder.sandBoxUuid,
                        })
                     );
                     dispatch(
                        saleActions.update({
                           target: "preSMSorder",
                           value: order,
                        })
                     );
                     setLoading(false);
                     history.push("/recap");
                  } else {
                     setLoading(false);
                     setErrors(res.data.errorCodeList);
                  }
               },
               () => {
                  return dispatch(update(sendNotif(res.status)));
               }
            );
         })
         ?.finally(() => {
            setTimeout(() => {
               setLoading(false);
            }, 6000);
         });
   }

   return (
      <>
         <Notifaction />
         <div className="page_content_subscription">
            <div className="container">
               <Header>
                  <Button
                     className={"blue outline"}
                     style={{ width: "100%" }}
                     onClick={() => {
                        setopen(true);
                     }}
                  >
                     {t("actions.annul")}
                  </Button>
                  <Modal open={open} onClose={() => setopen(false)}>
                     <div className="modal">
                        <h2>{t("actions.annul2")}</h2>
                        <div className="buttons">
                           <Button
                              className={"blue"}
                              onClick={() => {
                                 setopen(false);
                              }}
                           >
                              NON
                           </Button>
                           <Button
                              className={"blue outline"}
                              onClick={() => annul()}
                           >
                              OUI
                           </Button>
                        </div>
                     </div>
                  </Modal>
               </Header>
               <div className="main_container">
                  <div className="dropDownManager">
                     <ListDropDown blocState={blocState} onClick={openAll} />
                     <List blocState={blocState} onClick={closeAll} />
                  </div>
                  <FormProvider {...methods}>
                     <form
                        onSubmit={methods.handleSubmit(submit)}
                        onKeyDown={(e) => checkEnterKeyDown(e)}
                        noValidate
                        autoComplete="off"
                        className="form_sub"
                     >
                        <div className="wrapper">
                           {/* Bloc identité */}
                           <IdentityBloc
                              open={openIdentity}
                              setOpen={setOpenIdentity}
                           />
                           {/* Bloc PDL */}
                           <PDLBloc
                              open={openPdl}
                              setOpen={setOpenPdl}
                              resetErrors={() => setErrors([])}
                           />
                           {/* Bloc Offre */}
                           <OfferBloc open={openOffre} setOpen={setOpenOffre} />

                           {/* Bloc Facturation */}
                           <BillingBloc
                              open={openFacturation}
                              setOpen={setOpenFacturation}
                           />
                           {/* Bloc Moyen de paiement  */}
                           <PaymentBloc
                              open={openPayment}
                              setOpen={setOpenPayment}
                           />
                        </div>
                        {/* Bloc Résumé */}
                        <ResumeBloc title={t("recap.title")}>
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                              }}
                           >
                              {/* ICI */}
                              <ErrorCodeListDisplay errors={errors} />
                              {/* ICI */}
                              {loading && (
                                 <div
                                    style={{
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                    }}
                                 >
                                    <Loader />
                                 </div>
                              )}
                              {!loading && (
                                 <Button
                                    className="green"
                                    icon="arrow"
                                    type="submit"
                                 >
                                    {t("recap.labelButton")}
                                 </Button>
                              )}
                           </div>
                        </ResumeBloc>
                     </form>
                  </FormProvider>
               </div>
            </div>
         </div>
      </>
   );
};

export default Subscription;
